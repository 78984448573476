@font-face {
  font-family: 'NunitoSans Black';
  src: url(/d95152ab5a160491d28b3fce25bf4ff2.ttf); 
}

@font-face {
  font-family: 'NunitoSans Black Italic';
  src: url(/75ec9078a3f7472f3cdee1d312a390a2.ttf); 
}

@font-face {
  font-family: 'NunitoSans Bold';
  src: url(/08e53a516d2ba719d98da46c49b3c369.ttf); 
}

@font-face {
  font-family: 'NunitoSans Bold Italic';
  src: url(/655ce9395fcf8c21f45cfeca5bb280a4.ttf); 
}

@font-face {
  font-family: 'NunitoSans Regular';
  src: url(/4c8f447011eef80831b45edb1e5971e0.ttf); 
}

@font-face {
  font-family: 'NunitoSans Italic';
  src: url(/2d517b40dabe232416b73e3a721dc950.ttf); 
}

@font-face {
  font-family: 'NunitoSans Light';
  src: url(/74d36921be67fb8482bfd7324bd86790.ttf); 
}

@font-face {
  font-family: 'NunitoSans Light Italic';
  src: url(/d395ff0f45e6b030608de646ec278a35.ttf); 
}

@font-face {
  font-family: 'Lato Regular';
  src: url(/2d36b1a925432bae7f3c53a340868c6e.ttf); 
}

@font-face {
  font-family: 'Lato Light';
  src: url(/2fe27d9d10cdfccb1baef28a45d5ba90.ttf); 
}

@font-face {
  font-family: 'Concert One Regular';
  src: url(/1b40f0186d1a8b1500b4676b2e9a7ba1.ttf);
}